
import Button from "@/components/base/Button"
import Card from "@/components/base/card/Card"

import { mdiChevronRight } from "@mdi/js"

export default {
    name: "PartnerCard",
    components: { Card, Button },
    props: {
        /** A Partner containing values:
         * link: String,
         * img: String
         */
        partner: {
            type: Object,
            required: true,
        },
        fullimage: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        mdiChevronRight,
    }),
    computed: {
        cardBackground() {
            if (!this.fullimage) return
            return this.partner.img
        },
    },
}
