
import PartnerCard from "@/components/region/PartnerCard"
export default {
    name: "LandshutRegionSeoContent",
    components: { PartnerCard },
    props: {
        region: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        partners: [
            {
                img: require("@/assets/images/region/landshut/logo_bundesagentur_fuer_arbeit.svg"),
                title: "Bundesagentur",
                subtitle: "für Arbeit",
                link: "https://www.arbeitsagentur.de/bildung",
            },
            {
                img: require("@/assets/images/region/landshut/logo_sprungbrett.svg"),
                title: "Sprungbrett",
                subtitle: "Bayern",
                link: "https://www.sprungbrett-bayern.de/schueler/",
            },
            {
                img: require("@/assets/images/region/landshut/logo_ausbildung_klarmachen.svg"),
                title: "#AusbildungKlarmachen",
                subtitle: "Bundesagentur für Arbeit",
                link: "https://www.arbeitsagentur.de/m/ausbildungklarmachen/",
            },
            {
                title: "BERUFENET",
                subtitle: "Bundesagentur für Arbeit",
                link: "https://berufenet.arbeitsagentur.de/berufenet/faces/index",
            },
        ],
    }),
    computed: {
        regionName() {
            return this.region.name
        },
        seoText() {
            return this.region.about_us
        },
    },
}
